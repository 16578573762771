:root {
  --color-darkPink: rgb(198, 139, 189);
  --color-pink: #e99ad3bd;
  --color-palePink: #e99ad375;
  --color-blue: #819aff;
  --color-paleBlue: #adbdff;
  --color-darkGray: #3f3f3f;
  --color-gray: #9c969a;
}

p {
  font-size: 15px;
  font-family: 'Open Sans', sans-serif;
  color: rgb(72,  72, 72);
}

h1 {
  font-size: 2.2em;
  font-family: 'Lato', sans-serif;
  letter-spacing: 2px;
  line-height: 1.3em;
}

h5 {
  font-size: 1.1em;
  font-family: 'Open Sans', sans-serif;
}

h4 {
  font-size: 1.2em;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.2em;
}

h3 {
  font-size: 1.4em;
  font-family: 'Merriweather', serif;
}

div {
  box-sizing: border-box;
}

button:focus {
  outline: none;
}

body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

body::-webkit-scrollbar-thumb {
background-color: #E4B9D7;
outline: 1px solid rgb(198, 139, 189);
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.8);
}

.divider {
  margin: 20px 0;
  border-bottom: 1px solid #EBEBEB;
}

.ui.input.focus>input,
.ui.input>input:focus {
  border-color: rgb(198, 139, 189)!important;
}

/* inverted button semantic ui */

button.ui.inverted.button.basic {
  box-shadow: 0 0 0 2px lightgray inset!important;
  color: gray!important;
  border-radius: 150px;
  padding: 15px 25px;
}

button.ui.inverted.button.basic.active,
button.ui.inverted.button.basic.active:hover,
button.ui.inverted.button.basic:hover,
button.ui.inverted.button.basic:active,
button.ui.inverted.button.basic:focus {
  color: var(--color-blue) !important;
  box-shadow: 0 0 0 2px var(--color-blue) inset!important;
}